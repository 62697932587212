
import { g_scene_camera, lookBearing, computeShowPositionMessage } from "./camera.mjs";
import { Bearing } from "./coordinates.mjs";
import { gEventHandler } from "./events.mjs";
import { byId, css_hide } from "./html-util.mjs";

let g_is_locked: "Locked" | "Unlocked" = "Unlocked"
let g_device_bearing: Bearing = new Bearing({d:0});

export function initDevice()
{
    startDeviceMotion();
}

interface DeviceOrientationEventiOS extends DeviceOrientationEvent
{
    webkitCompassHeading: number | null;
    webkitCompassAccuracy: number | null;
    requestPermission?: () => Promise<'granted' | 'denied'>;
}


function showHideforCompass(action: "show" | "hide")
{

    const display = byId("compass-info");
    const button = byId("compass-button-span");
    const lock = byId("compass-button-lock-span");
    if (button && display && lock)
    {
        // display.style.visibility = action == "show" ? 'visible' : 'hidden';
        // button.style.visibility = action == "show" ? 'hidden' : 'visible';
        display.style.display = action == "show" ? 'inline-block' : 'none';
        lock.style.display = action == "show" ? 'inline-block' : 'none';
        button.style.display = action == "show" ? 'none' : 'inline-block';

        if (button.children[0])
            button.children[0].addEventListener('click', () => iosStartDeviceOrientation());
        if (lock.children[0])
            lock.children[0].addEventListener('click', () => toggleLock() );
    }
}

function showState(state:string)
{
    const control = byId("compass-info")
    if (control)
        control.innerHTML = state;
}

function iosStartDeviceOrientation()
{
    const has_DOE = typeof(DeviceOrientationEvent) !== "undefined";

    const requestPermission = has_DOE && (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS).requestPermission;
    if (requestPermission)
        requestPermission()
            .then(response =>
            {
                if (response == "granted")
                {
                    window.addEventListener("deviceorientation", (e) =>
                    {
                        const heading = (e as DeviceOrientationEventiOS).webkitCompassHeading;
                        if (heading)
                            {
                                const bearing = new Bearing({d: heading});
                                showState(bearing.toCardinal());      
                                maybeLockCameratoOrientation(bearing);
                            }
                    })
                }

                showHideforCompass("show");
            })
}




function compassHeading(alpha: number | null, beta: number | null, gamma: number | null) 
{
    const degtorad = Math.PI / 180; // Degree-to-Radian conversion

    const _x = beta ? beta * degtorad : 0; // beta value
    const _y = gamma ? gamma * degtorad : 0; // gamma value
    const _z = alpha ? alpha * degtorad : 0; // alpha value

    const cX = Math.cos(_x);
    const cY = Math.cos(_y);
    const cZ = Math.cos(_z);
    const sX = Math.sin(_x);
    const sY = Math.sin(_y);
    const sZ = Math.sin(_z);

    // Calculate Vx and Vy components
    const Vx = - cZ * sY - sZ * sX * cY;
    const Vy = - sZ * sY + cZ * sX * cY;

    // Calculate compass heading
    let compassHeading = Math.atan(Vx / Vy);

    // Convert compass heading to use whole unit circle
    if (Vy < 0)
    {
        compassHeading += Math.PI;
    } 
    else if (Vx < 0)
    {
        compassHeading += 2 * Math.PI;
    }

    return compassHeading * (180 / Math.PI); // Compass Heading (in degrees)
}

function startDeviceMotion()
{
    let heading: number | null = null;

    showHideforCompass("show");
    const has_DOE = typeof(DeviceOrientationEvent) !== "undefined";

    const requestPermission = has_DOE ? (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS).requestPermission : "undefined";
    if (typeof requestPermission === 'function')
    {
        showState('perm.');

        showHideforCompass('hide');
    }
    else if ('AbsoluteOrientationSensor' in window)
    {
        let sensor = new AbsoluteOrientationSensor({frequency:1});
        let old_bearing = new Bearing({d:0})
        sensor.addEventListener('reading',
            (event) => 
            {
                const q = (event.target as AbsoluteOrientationSensor).quaternion;
                if (q)
                {
                    //    https://stackoverflow.com/questions/77239894/2d-compass-heading-from-absoluteorientationsensor-quaternion-for-the-normal-to-t
                    let [x, y, z, w] = q;
                    let a = Math.atan2(2 * x * y + 2 * z * w, 1 - 2 * y * y - 2 * z * z) * (180 / Math.PI);
                    if (a < 0) a = 360 + a;
                    heading = 360 - a;

                    const bearing = new Bearing({d: 360 - a});
                    if (Math.abs(old_bearing.toDegrees() - bearing.toDegrees()) > 1)
                    {
                        old_bearing = new Bearing({d:bearing.toDegrees()})
                        showState(bearing.toCardinal()); 

                        g_device_bearing = bearing;

                        maybeLockCameratoOrientation(bearing);
                    }
                }
            });

        sensor.addEventListener("error", (event: SensorErrorEvent) => 
        {
            console.log(`Sensor is not available. ${event.error.message}`);
            showState("N/A")
            const lock = byId("compass-button-lock-span");
            if (lock)
                css_hide(lock);
        });

        showState('sensor')
        sensor.start();
    }
    if ('ondeviceorientationabsolute' in window) 
    {
        showState('event');

        let old_bearing = new Bearing({d:0})

        function onOrientationAbsolute(ev: Event) 
        {
            const event = ev as DeviceOrientationEvent;

            const heading = compassHeading(event.alpha, event.beta, event.gamma);
            
            const bearing = new Bearing({ d: heading });
            if (Math.abs(old_bearing.toDegrees() - bearing.toDegrees()) > 1)
            {
                old_bearing = new Bearing({ d: bearing.toDegrees() })

                showState(bearing.toCardinal());

                g_device_bearing = bearing;
                maybeLockCameratoOrientation(bearing);
            }
        }
        window.addEventListener('deviceorientationabsolute', onOrientationAbsolute);
    }
    else
    {
        showState('none');
    }
}

function toggleLock()
{
    g_is_locked = g_is_locked == "Locked" ? "Unlocked" : "Locked" 
    const compass_button_lock = byId("compass-button-lock") as HTMLButtonElement;
    if (compass_button_lock)
        compass_button_lock.innerHTML = g_is_locked as string; //== "Locked" ? "🔒" : "🔓"

    if (g_is_locked)
        maybeLockCameratoOrientation(g_device_bearing);
}

function maybeLockCameratoOrientation(bearing: Bearing)
{
    if (g_is_locked== "Locked")
        {
            gEventHandler.setDirty();
            lookBearing(bearing);
 
            console.log('look bearing', new Date())
            computeShowPositionMessage(g_scene_camera.camera);            
        }
}