import { PerspectiveCamera } from "three"

import { LLA, ECEF, Bearing } from './coordinates.mjs'

import { getCameraPosition, getCameraBearing, g_scene_camera, placeCamera, postCameraMove, getCameraViewAngle,
                                graphicsInitialise, lookBearing, zoomCamera } from './camera.mjs'

import { EventHandler, gEventHandler } from './events.mjs'

import { realmEnum, Realm } from './realm.mjs'

import { DEM } from './dem.mjs'

import { initDialogs } from './dialogs.mjs'

import { Labeller } from "./labeller.mjs"

import { initPopups, toast } from './popups.mjs'

import { HillsList } from './dobih.mjs'

import { browserCompatible, urlParameterValues } from "./installation.mjs"

import { Settings, g_settings, initSettings, VisualisationType } from './settings.mjs'
import { TileNameAndResolution } from "./resolution.mjs"

import { initDevice } from './device.mjs'
import { inrange } from "./html-util.mjs"
import { moveLights, setLightsForRealm } from "./lights.mjs"

/** location of camera, etc */
export class viewerPositionDirectionZoom
{
    constructor(LLA: LLA, bearing: Bearing, zoom: number, view?: string)
    {
        this.latitude = LLA.latitude;
        this.longitude = LLA.longitude;
        this.altitude = LLA.altitude;
        this.bearing = bearing;
        this.zoom = zoom;
        this.material_type = Settings.toVisualisation(view);

        this.ok = inrange(this.latitude, -180, 180) && inrange(this.longitude, -180, 180) && 
                        inrange(this.altitude, 0, 10) && 
                        this.bearing.isValid() && inrange(this.zoom, 0.5, 20);
    }

    readonly latitude: number;
    readonly longitude: number;
    readonly altitude: number;
    readonly bearing: Bearing;
    readonly zoom: number;
    readonly material_type: VisualisationType;
    readonly ok: boolean;

    static fromCamera(camera: PerspectiveCamera) : viewerPositionDirectionZoom
    {
        const position = getCameraPosition(camera);
        const bearing = getCameraBearing(camera, position);
        const view = Settings.fromVisualisation(g_settings.material_type);

        const location = new viewerPositionDirectionZoom(position.lla, bearing, camera.zoom, view);

        return location;
    }
    static fromLLA(lla : LLA) : viewerPositionDirectionZoom
    {
        const camera = g_scene_camera.camera;
        const position = getCameraPosition(camera);
        const bearing = getCameraBearing(camera, position);
        const view = Settings.fromVisualisation(g_settings.material_type);

        const location = new viewerPositionDirectionZoom(lla, bearing, camera.zoom, view);

        return location;
    }
    static fromURLParameter(_string: string)
    {
        let _return : viewerPositionDirectionZoom | null = null;

        // (53.6299:-2.5157:0.7656:340:6)
        // const latlong_regex = /\(([\d|.|-]*):([\d|.|-]*):([\d|.|-]*):([\d|.|-]*):([\d|.|-]*)\)/gm
        // const latlong_regex =/\(([\d|.|-]*):([\d|.|-]*):([\d|.]*):(\d*):(\d*)(:[A-Z])?\)/gm;
        const latlong_regex =/\(([\d|.|-]*):([\d|.|-]*):([\d|.]*):(\d*):(\d*)(:[A-Z])?\)?/gm;   // trailing ) optional
        const bih_regex     = /\((\d+)\)/gi;
        let mm = latlong_regex.exec(_string);
        if (mm)
        {
            const latitude = parseFloat(mm[1] ?? "1");
            const longitude = parseFloat(mm[2] ?? "1");
            const altitude = parseFloat(mm[3] ?? "1");
            const bearing = new Bearing({d:parseFloat(mm[4] ?? "1")});
            const zoom = parseFloat(mm[5] ?? "1");
            const view: string | undefined = mm[6] ? mm[6].substring(1) : undefined;

            _return = new viewerPositionDirectionZoom(new LLA(latitude, longitude, altitude), bearing, zoom, view);
        }
        else
        {
            mm = bih_regex.exec(_string);
            if (mm)
            {
                const id = parseInt(mm[1] ?? "1");
                const sought = HillsList.current().hills.find(hill => hill.number == id);
                if (sought)
                    _return = new viewerPositionDirectionZoom(sought.LLA, new Bearing({d:270}), 2, "P");
            }
           
        }
        return _return;
    }
    static fromRealm(rm: realmEnum)
    {
        const realm = Realm.realms.get(rm);
        if (realm)
        {
            const lla = realm.default_location;
            return this.fromLLA(lla);
        }
        else
        {
            return null;
        }
    }
    toLocationString() : string
    {
        const f4 = (n:number) => n.toFixed(4);
        const f0 = (n:number) => n.toFixed(0);
        const view = Settings.fromVisualisation(this.material_type);
        const readable = `(${f4(this.latitude)}:${f4(this.longitude)}:${f4(this.altitude)}:${f0(this.bearing.toDegrees())}:${f0(this.zoom)}:${view})`
        
        return `${readable}`;
    }
    toURLParameter() : string
    {
        return `?loc=${this.toLocationString()}`
    }
    goto() : boolean
    {
        if (this.ok)
        {
            const lla = new LLA(this.latitude, this.longitude, this.altitude);

            g_settings.material_type = this.material_type;
            gEventHandler.visualisations_initialise();

            const went = gotoLLAPosition(lla, this.bearing, this.zoom);
            return went;
        }
        else
        {
            return false;
        }
    }
}

/** move camera to a given position lat/long/alt */
function gotoLLAPosition(position_lla: LLA, bearing: Bearing, zoom: number) : boolean
{
    if (Realm.validLatLong(position_lla) && 0 <= position_lla.altitude && position_lla.altitude < 9999)
    {
        Realm.setGlobalRealmfromLLA(position_lla);
        
        setLightsForRealm();

        const hill_ecef_position = ECEF.fromLLA(position_lla);

        const position = getCameraPosition(g_scene_camera.camera);

        placeCamera(g_scene_camera.camera, hill_ecef_position, position.ecef);

        // ensure camera looks at horizon
        const angle = getCameraViewAngle();
        const rotation =  angle - Math.PI / 2;
        g_scene_camera.camera.rotateX(rotation);

        postCameraMove(g_scene_camera.camera);

        const tile_set_take2 = TileNameAndResolution.getTileSettake2(position_lla, g_settings.fog, g_scene_camera.camera.zoom);

        DEM.updateFromRequiredtake2(tile_set_take2);


        if (bearing)
            lookBearing(bearing);

        if (zoom)
            zoomCamera(g_scene_camera.camera, zoom);

        gEventHandler.setDirty();

        return true;
    }
    else
    {
        toast(`Position ${position_lla.toString()} not supported`);
        return false;
    }
}

/** Load up the data and render it */
export function initialLoadDOBIHmakeLabeller(parameters? : urlParameterValues)
{
    const promises = 
    
    (Object.values(realmEnum) as Array< realmEnum >)
        .map(rm => HillsList.Loader(rm));

    Promise.allSettled(promises)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(results =>
            {
                Labeller.makeLabeller();

                let ok = false;
                if (parameters && parameters.loc)
                {
                    const location = viewerPositionDirectionZoom.fromURLParameter(parameters.loc);
                    if (location)
                        ok = location.goto();
                }

                if (!ok)
                {
                    const location = g_settings.location;
                    if (location && location.ok)
                        ok = location.goto();
                }

                if (!ok)
                {
                    const dobih = HillsList.list.get(realmEnum.BI);
                    const pillar = dobih ? dobih.hills.find(hill => hill.name == 'Pillar') : null;
                    if (pillar)
                    {
                        gotoLLAPosition(pillar.LLA, new Bearing({d:180}), 1.0);
                    }    
                }
            });
}


/** create graphics context, camera, etc, load up the initial viewed data */
export function mainInitialise(compatible: browserCompatible, parameters? : urlParameterValues)
{
    if (!compatible.ok)
    {
        document.body.innerHTML = `Cannot run VHills.App ${compatible.message}`;
        return;
    }

    // renderer
    try
    {
        // pick up the saved settings
        initSettings();
    
        initDialogs();

        initPopups();
        
        EventHandler.makeEventHandler();

        graphicsInitialise();

        setTimeout(() => window.dispatchEvent(new Event('resize')), 50); // resize the canvas, allowing html to load

        initialLoadDOBIHmakeLabeller(parameters);

        initDevice();
    }
    catch (_error)
    {
        const err: Error = _error as Error;
        console.debug(err);
        toast(err.message);
    }
}

